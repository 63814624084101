import React from 'react';

import Link from 'next/link';

import Grid from '@mui/material/Unstable_Grid2';

import PropertyCategory from '@/components/Property/PropertyCategory';
// import {SignUp} from '@/components/SignUp';

export interface PropertyCategoriesInterface {
  id: string | number;
  name: string;
  url: string;
  image: string;
}

export interface PropertyCategoriesProps {
  categories: Array<PropertyCategoriesInterface>;
}

const styleLink = {
  textDecoration: 'none',
};

const PropertyCategories = ({categories = []}: PropertyCategoriesProps): JSX.Element => (
  <Grid container spacing={3}>
    {categories.map((category: PropertyCategoriesInterface) => (
      <Grid key={`PropertyCategory-${category.id}`} xs={6} sm={6} md={4} pt={3}>
        <Link href={`${category.url}`} style={styleLink}>
          <PropertyCategory title={category.name} image={category.image} />
        </Link>
      </Grid>
    ))}
    {/* <Grid container xs={12} md={8} mt={0} mb={1} direction="column" justifyContent="end" alignItems="end">
      <Grid xs={12} md="auto">
        <SignUp />
      </Grid>
    </Grid> */}
  </Grid>
);

export default PropertyCategories;
