import React from 'react';

import {Chip} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import ImageRatio from '@/components/Image/ImageRatio';

// Styles
const styleWrapper = {
  display: 'grid',
  height: '100%',
};
const styleImage = {
  borderRadius: '20px',
};
const stylePaper = {
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
const styleChip = {
  borderColor: 'text.primary',
  color: 'text.primary',
  fontSize: '1rem',
  textTransform: 'upperCase',
  fontWeight: '600',
};

export interface PropertyProps {
  title: string;
  address: string;
  area: number;
  tenancy: string;
  image: string;
  tag?: string;
}

const Property = ({title = '', address = '', area = 0, tenancy = '', image = '', tag = ''}: PropertyProps): JSX.Element => {
  const areaValue =
    area &&
    area.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

  return (
    <Box sx={styleWrapper}>
      <Card variant="outlined" sx={stylePaper}>
        {/* <CardHeader title="Property Title" subheader="Property Address" /> */}
        <CardContent sx={{p: 0, mb: 4}}>
          <Grid container spacing={0.5}>
            <Grid xs={12}>
              <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography fontWeight="400">{address}</Typography>
            </Grid>
            {areaValue && (
              <Grid xs={12}>
                <Typography fontWeight="bold">Area (sqm): {areaValue} sqm</Typography>
              </Grid>
            )}
            {tenancy && (
              <Grid xs={12}>
                <Typography fontWeight="bold">Tenancy: {tenancy}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Box>
          <CardMedia title={title}>
            <Grid container>
              <ImageRatio image={image} title={title} aspectRatio="1 / 1" sx={styleImage} />
            </Grid>
          </CardMedia>
          {tag && (
            <CardActions sx={{pl: 0}}>
              <Grid container spacing={1} pt={1}>
                <Grid xs={12}>
                  <Chip variant="outlined" label={tag} sx={styleChip} />
                </Grid>
              </Grid>
            </CardActions>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Property;
