import React from 'react';

import Link from 'next/link';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Button} from '@/components/Button';
import ImageRatio from '@/components/Image/ImageRatio';

const styleMobile = {
  display: {xs: 'flex', md: 'none'},
};
const styleImageWrapper = {
  width: '100%',
};
const styleImage = {
  borderRadius: '20px',
};
const styleLink = {
  textDecoration: 'none',
};

const MAX_WIDTH_DEFAULT = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

const Sustainability = () => (
  <Grid container maxWidth={`${MAX_WIDTH_DEFAULT}px`} mx="auto" px={3}>
    <Grid container mt={10} mb={7}>
      <Grid xs={12} md={6} pr={{xs: 0, md: 6}} mb={{xs: 5, md: 11}}>
        <Typography variant="h5" color="white" mb={2}>
          SUSTAINABILITY
        </Typography>
        <Typography variant="h2" fontSize="clamp(30px, 2vw + 1rem, 50px)" mb={4}>
          How we&apos;re delivering on our promise.
        </Typography>
        <Grid sx={{...styleMobile, ...styleImageWrapper}}>
          <ImageRatio image="/images/sustainability@2x.png" title="banner background" aspectRatio="1 / 1" sx={styleImage} />
        </Grid>
        <Typography variant="h2" color="white" mt={{xs: 4, md: 0}} fontSize="clamp(30px, 2vw + 1rem, 50px)">
          &apos;Buy, develop and hold&apos; is our motto. And the way this comes to life is a focus on long-term operational efficiency, durability, and
          flexibility. So the spaces we design and deliver for our clients must be done so in a sustainable way.
        </Typography>
      </Grid>
      <Grid xs={0} md={6} mt={5}>
        <ImageRatio image="/images/sustainability@2x.png" title="banner background" aspectRatio="1 / 1" maxHeight="730px" sx={styleImage} />
      </Grid>
      <Grid xs={12} md={5} mdOffset={1} pr={{xs: 0, md: 6}} sx={styleImageWrapper}>
        <ImageRatio image="/images/commercial@2x.png" title="banner background" aspectRatio="6 / 5" sx={styleImage} />
      </Grid>
      <Grid xs={12} md={3}>
        <Typography mt={{xs: 4, md: 1}} mb={4}>
          The actions we take now can transform the way our commercial buildings are designed and built, dramatically reducing our carbon footprint, and
          improving the health and wellbeing of our people.
        </Typography>
        <Link href="/sustainability" style={styleLink}>
          <Button variant="contained" color="light" size="large" circular disableElevation>
            Find out more
          </Button>
        </Link>
      </Grid>
    </Grid>
  </Grid>
);

export default Sustainability;
