'use client';

import React, {useMemo, useState} from 'react';

import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Divider} from '@/components/Divider';
import FeaturedListingPropertyGallery from '@/components/FeaturedListings/FeaturedListingPropertyGallery';
import PropertyGallery from '@/components/Property/PropertyGallery';
import {Title} from '@/components/Title';

export interface FeaturedListingsProps {
  autoplay?: boolean;
  description: string;
  interval?: number;
  properties?: object;
  showPagination?: boolean;
  showPaginationArrows?: boolean;
  showSearch?: boolean;
  showTitleArrows?: boolean;
  subtitle?: string;
  title?: string;
  itemsPerPageMobile?: number;
  itemsPerPageDesktop?: number;
}

const FeaturedListings = ({
  autoplay = false,
  description = '',
  interval = 3000,
  properties = {},
  showPagination = false,
  showPaginationArrows = false,
  showSearch = false,
  showTitleArrows = false,
  subtitle = '',
  title = '',
  itemsPerPageMobile = 1,
  itemsPerPageDesktop = 3,
}: FeaturedListingsProps): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const imagesMobile = FeaturedListingPropertyGallery(properties, itemsPerPageMobile, true);
  const imagesDesktop = FeaturedListingPropertyGallery(properties, itemsPerPageDesktop, true);
  const itemCount = (imagesMobile && imagesMobile.length) ?? 0;
  const pageCountMobile = Math.ceil(itemCount / itemsPerPageMobile) ?? 0;
  const pageCountDesktop = Math.ceil(itemCount / itemsPerPageDesktop) ?? 0;

  const disabledButtonPrev = useMemo(() => activeStep === 0, [activeStep]);
  const disabledButtonNext = useMemo(() => activeStep >= pageCountDesktop - 1, [activeStep, pageCountDesktop]);
  const showPaginationElements = useMemo(() => pageCountDesktop > 1, [pageCountDesktop]);
  const MAX_WIDTH_DEFAULT = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

  return (
    <Grid container maxWidth={`${MAX_WIDTH_DEFAULT}px`} mx="auto" px={3} pb={8}>
      <Grid xs={12}>
        <Title
          title={title}
          subtitle={subtitle}
          showPaginationElements={showPaginationElements}
          showTitleArrows={showTitleArrows}
          showSearch={showSearch}
          handleBack={handleBack}
          handleNext={handleNext}
          disabledButtonPrev={disabledButtonPrev}
          disabledButtonNext={disabledButtonNext}
        />
      </Grid>

      {description && (
        <Grid xs={12} lg={3} py={3} pr={{xs: 0, lg: 8}}>
          <Typography>{description}</Typography>
          <Divider sx={{mt: 3, display: {xs: 'block', lg: 'none'}}} />
        </Grid>
      )}

      {itemCount > 0 && (
        <Hidden lgUp>
          <Grid xs={12} lg={description ? 9 : 12} pt={{xs: 0, lg: 2}}>
            <PropertyGallery
              activeStep={activeStep}
              autoplay={autoplay}
              images={imagesMobile}
              interval={interval}
              itemCount={itemCount}
              pageCount={pageCountMobile}
              showPagination={showPagination}
              showPaginationArrows={showPaginationArrows}
            />
          </Grid>
        </Hidden>
      )}

      {itemCount > 0 && (
        <Hidden lgDown>
          <Grid xs={12} lg={description ? 9 : 12} pt={{xs: 0, lg: 2}}>
            <PropertyGallery
              activeStep={activeStep}
              autoplay={autoplay}
              images={imagesDesktop}
              interval={interval}
              itemCount={itemCount}
              pageCount={pageCountDesktop}
              showPagination={showPagination}
              showPaginationArrows={showPaginationArrows}
            />
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export default FeaturedListings;
