import React from 'react';

import Link from 'next/link';
import {useRouter} from 'next/router';

import Grid from '@mui/material/Unstable_Grid2';

import chunk from 'lodash/chunk';

import {PropertySummaryInterface} from '@/interfaces/properties';
import PropertyCard from '@/components/Property/PropertyCard';

const stylePropertyWrapper = {
  margin: 0,
  padding: 0,
};

const styleLink = {
  textDecoration: 'none',
  display: 'block',
  height: '100%',
  flex: '1 1 auto',
};

const FeaturedListingPropertyGallery = (properties = {}, columns = 4, splitIntoPages = false): Array<JSX.Element> => {
  const elementsProperties: Array<JSX.Element> = [];
  const router = useRouter();

  Object.keys(properties).forEach(key => {
    const property = properties[key as keyof typeof properties] as PropertySummaryInterface;
    let url = `/auckland-properties-for-sale-and-lease/${property.slug}`;
    if (property.tenancy === 'Multiple') {
      const category = router.query.category || router.query.categoryId;
      url = `/auckland-property-vacancies-and-rentals/${category}/${property.tenancy_id}`;
    }

    elementsProperties.push(
      <Grid key={`properties-${property.tenancy_id}`} xs={12} md={12 / Math.min(columns, 4)}>
        <Link href={url} style={styleLink}>
          <PropertyCard
            title={property.name}
            address={property.street}
            area={property.area}
            tenancy={property.tenancy}
            image={property.hero}
            tag={property.type}
          />
        </Link>
      </Grid>
    );
  });

  if (splitIntoPages)
    return chunk(elementsProperties, columns).map((page, pageIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={`properties-${pageIndex}`} container spacing={3} sx={stylePropertyWrapper}>
        {page}
      </Grid>
    ));

  return [
    <Grid key="properties-0" container spacing={3} sx={stylePropertyWrapper}>
      {elementsProperties}
    </Grid>,
  ];
};

export default FeaturedListingPropertyGallery;
