'use client';

import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {CategoryInterface} from '@/interfaces/properties';
import {Divider} from '@/components/Divider';
import {PropertyCategories} from '@/components/Property';
import {Title} from '@/components/Title';

export interface BrowseCategoriesProps {
  description: string;
  categories?: Array<CategoryInterface>;
  subtitle?: string;
  title: string;
}

const MAX_WIDTH_DEFAULT = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

const BrowseCategories = ({description = '', categories = [], subtitle = '', title = ''}: BrowseCategoriesProps): JSX.Element => {
  const itemCount = categories.length ?? 0;
  const filteredCategories = categories.filter(category => category.name !== 'All');

  return (
    <Grid container maxWidth={`${MAX_WIDTH_DEFAULT}px`} mx="auto" px={3}>
      <Grid container>
        <Grid xs={12}>
          <Title title={title} subtitle={subtitle} />
        </Grid>

        {description && (
          <Grid xs={12} lg={3} py={3} pr={{xs: 0, lg: 8}}>
            <Typography>{description}</Typography>
            <Divider sx={{mt: 3, display: {xs: 'block', lg: 'none'}}} />
          </Grid>
        )}

        {itemCount > 0 && (
          <Grid xs={12} lg={9} pt={{xs: 0, lg: 2}}>
            <PropertyCategories categories={filteredCategories} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BrowseCategories;
