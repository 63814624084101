import React from 'react';

import Head from 'next/head';

import {Box, useMediaQuery} from '@mui/material';
import {ThemeOptions, useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import useSWR from 'swr';

import {BannerLarge} from '@/components/Banner';
import {BrowseCategories} from '@/components/BrowseCategories';
import {FeaturedListings} from '@/components/FeaturedListings';
import {Sustainability} from '@/components/Sustainability';
import {TitleHome, TitleHomeDescription} from '@/components/Title';

// Styles
const styleSectionFeatured = (theme: ThemeOptions) => ({
  backgroundColor: 'background.paper',
  borderRadius: '0 0 50px 50px',

  // @ts-ignore
  [theme.breakpoints.down('md')]: {
    borderRadius: '0 0 20px 20px',
  },
});
const styleSectionSustainability = (theme: ThemeOptions) => ({
  backgroundColor: 'background.highlight',
  borderRadius: '0 0 50px 50px',
  top: '-50px',
  position: 'relative',
  paddingTop: '50px',
  zIndex: 2,

  // @ts-ignore
  [theme.breakpoints.down('md')]: {
    borderRadius: '0 0 20px 20px',
  },
});
const styleSectionCategories = {
  backgroundColor: 'background.paper',
  zIndex: 1,
  top: '-50px',
  position: 'relative',
};

const fetcher = (url: string) => fetch(url).then(res => res.json());

const Page = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const {data: categories, error: categoryError} = useSWR('/api/categories', fetcher);
  const {data: properties, error: propertyError} = useSWR('/api/properties/featured', fetcher);
  const {data: propertiesFeatured} = properties || {};

  if (categoryError || propertyError) return <p>There is an error.</p>;

  return (
    <Box>
      <Head>
        <title>James Kirkpatrick Group LTD</title>
        <meta
          name="description"
          content="Commercial office space in Auckland to lease | offices to rent | warehouse space | retail space to lease | James Kirkpatrick Group Ltd"
        />
        <meta
          name="keywords"
          content="warehouse for lease, office for lease, leasing, rental, retail, offices, warehouses, showrooms, showroom/warehouse, shops, workshops, yards, car parks, car yards, studios,  accommodation, Auckland, New Zealand."
        />
      </Head>
      <BannerLarge title={<TitleHome />} description={<TitleHomeDescription />} videoUrl={isDesktop && '/videos/banner.webm'} />
      {propertiesFeatured && (
        <Grid xs={12} sx={styleSectionFeatured(theme)}>
          <FeaturedListings
            title="Featured Listings"
            description="James Kirkpatrick Group has a great range of commercial and industrial properties for lease available now, including warehousing, office warehouse units, office buildings, showrooms, retail and yard space. Check out our listings today!"
            properties={propertiesFeatured}
            autoplay={false}
            showPagination
            showTitleArrows
          />
        </Grid>
      )}
      <Grid xs={12} sx={styleSectionSustainability(theme)}>
        <Sustainability />
      </Grid>
      <Grid xs={12} sx={styleSectionCategories}>
        <BrowseCategories
          title="Browse Categories"
          description="View our range of quality properties here."
          categories={categories}
        />
      </Grid>
    </Box>
  );
};

export default Page;
